
.grid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 15px;
  column-gap: 15px;

  @media (min-width: 850px) {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 330px);
    margin-left: 10px;
  }
}