@mixin item_details() {
  display: inline-block;
  border-radius: 5px;
  margin-right: 3px;
  padding: 3px;
  font-size: 10px;
  font-weight: 800;
}
.item_card {
  width: 100%;
  border-style: solid;
  margin: 10px;
  font-weight: 600;
  border-width: 1px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 850px) {
    max-width: 220px;
  }
}
.item_name {
  margin: 5px 0px;
}
.item_calories {
  background-color: #17a2b8;
  @include item_details;
  color: #ffffff;
}

.item_type {
  background-color: #007bff;
  @include item_details;
  color: #ffffff;
  margin-bottom: 12px;
}

.item_category {
  background-color: #ffc107;
  @include item_details;
}

.item__priceQtyContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price {
  padding: 15px 0px 5px;
}
