.order_card {
  background-color: #f8f9fa;
  width: 100%;
  padding: 20px;
  position: fixed;
  bottom: 0;
  right: 0;

  h3 {
    color: #007bff;
    padding-bottom: 16px;
    font-weight: 600;
  }
  .order_costs {
    li {
      display: flex;
      justify-content: space-between;
      padding-top: 5px;
    }
  }

  .order_display {
    overflow-y: auto;
    height: 100px;
    .item_display {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      gap: 2px;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
    }
  }

  .total {
    font-weight: 700;
  }

  .order_btn {
    background-color: #1da74c;
    border-style: none;
    color: #fff;
    padding: 15px;
    font-size: 20px;
    border-radius: 5px;
    margin-top: 10px;
    font-weight: 600;
  }
}


@media (min-width: 850px) {
  .order_card {
    position: fixed;
    width: 300px;
    height: 500px;
    right: 0;

    .order_display {
      height: 260px;
    }
  }
}
