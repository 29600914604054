.footer {
  border-top: 1px solid #ddd;
  margin-bottom: 42px;
  padding-top: 24px;

  &__text {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-weight: 500;
    font-size: 15px;
  }
}
