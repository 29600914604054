.App-header {
  margin-bottom: 15px;
  text-align: center;
  padding: 15px;
  font-size: 26px;
  font-weight: 600;
  box-shadow: 0 4px 9px -2px lightgrey;
}

.blue-text {
  color: #17a2b8;
}


@media screen and (min-width: 745px) {
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}



