.quantityButtons {
  display: flex;
  align-items: center;
  gap: 5px;
}

.quantityButtons__btn {
  background-color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 18px;
  padding: 18px;
  border: none;
  border-radius: 3px;
  color: #ffffff;
  font-size: 20px;
}

.quantityButtons__qty {
  background-color: #f4f4f8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 18px;
  padding: 12px;
  border: none;
  border-radius: 3px;
  font-size: 15px;
}
