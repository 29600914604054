.button {
  border: 1px #17a2b8 solid;
  border-radius: 4px;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 25px;
  font-weight: 600;
  padding: 20px 20px;
  cursor: pointer;
}
